import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../reducers/userInfo";
import naegift_ko_logo from "../images/naegift_ko_logo.png"

export default function Header() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const navigate = useNavigate();
    console.log(userInfo);

    const logOut = () => {
        dispatch(userLogout()); // 로그아웃 액션 디스패치
        localStorage.removeItem('user'); // 로컬 스토리지에서 사용자 정보 제거
        window.location.href = "https://shopadmin-test.naegift.com/login";
    }

    useEffect(() => {
  
    }, []);

    return (
    <>
        <header>
            <section className="header_box">
                <h1>
                    <a href="https://www-test.naegift.com/">
                        <img className="header_logo" src={naegift_ko_logo} alt="내기프트"/>
                    </a>
                </h1>
                <nav>
                    <ul className="header_nav">
                        <li>                            
                            <a className="select" href="https://www-test.naegift.com/">서비스소개</a>
                        </li>
                        <li>
                            <a href="https://www-test.naegift.com/">상점지원</a>
                        </li>
                    </ul>
                </nav>
                <ul className="header_login">
                {userInfo?.id ?
                    <>
                        <li onClick={logOut}>로그아웃</li>
                        <li><a href="https://shopadmin-test.naegift.com/gift-list">마이페이지</a></li>
                    </>
                :
                    <>
                        <li><a href="https://shopadmin-test.naegift.com/login">로그인</a></li>
                        <li><a href="https://www-test.naegift.com/user-signup">회원가입</a></li>
                    </>
                }
                </ul>
            </section>
        </header>
    </>
    );
}