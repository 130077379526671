import React, {useState, useEffect} from 'react';

import page_left_02 from '../images/page_left_02.png';
import page_left_01 from '../images/page_left_01.png';
import page_right_01 from '../images/page_right_01.png';
import page_right_02 from '../images/page_right_02.png';
import { Config } from "../common/config";


function Pagination({ totalPage, onPageChange }) {
  const PageSize = 10; // 한 번에 보여줄 페이지 갯수
  const [currentPage, setCurrentPage] = useState(1);

  const getPageNumbers = () => {
    const pageNumbers = [];
    // 현재 페이지 그룹의 시작 페이지
    const startPage = Math.floor((currentPage - 1) / PageSize) * PageSize + 1;

    // 현재 페이지 그룹의 끝 페이지
    const endPage = Math.min(startPage + PageSize - 1, totalPage);


    if(endPage > 0){
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const handlePageClick = (page) => {
    // 페이지 클릭 시 해당 페이지로 이동
    setCurrentPage(page);
    // 외부에서 제공한 콜백 함수 호출
    onPageChange(page);
  };

  const handlePrevClick = () => {
    // "이전 페이지" 클릭 시 10 페이지 이전으로 이동
    setCurrentPage((prevPage) => Math.max(prevPage - PageSize, 1));
    onPageChange(Math.max(currentPage - 1, 1));
  };

  const handleNextClick = () => {
    // "다음 페이지" 클릭 시 10 페이지 이후로 이동
    setCurrentPage((prevPage) => Math.min(prevPage + PageSize, totalPage));
    onPageChange(Math.min(currentPage + PageSize, totalPage));
  };

  const handleFirstPageClick = () => {
    setCurrentPage(1);
    onPageChange(1);
  };

  const handleLastPageClick = () => {
    setCurrentPage(totalPage);
    onPageChange(totalPage);
  };



  return (
    // <div className="button_box">
    // <ul className="page_box">
      <>
        <li>
          <button onClick={handleFirstPageClick}>
            <img src={page_left_02} />
          </button>
        </li>
        <li>
          <button onClick={handlePrevClick}>
            <img src={page_left_01} />
          </button>
        </li>
        {getPageNumbers().map(function(page) {
          return (
            <li style={{ marginLeft: '10px', cursor: 'pointer', textDecoration: currentPage === page ? 'underline' : ''}}
              key={page}
              // className={currentPage === page ? 'select' : ''}
              onClick={() => handlePageClick(page)}
            >
              {page}
            </li>
          );
        })}
        <li style={{ marginLeft: '10px'}}>
          <button onClick={handleNextClick}>
            <img src={page_right_01} />
          </button>
        </li>
        <li>
          <button onClick={handleLastPageClick}>
            <img src={page_right_02} />
          </button>
        </li>
      </>
    // </ul>
    // </div>
  );
}

export default Pagination;