import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import { Config } from "../../common/config";
import axios from "axios";


export default function TestPage(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const receivedData = location.state;
    // console.log("receivedData : "+JSON.stringify(receivedData));
    const qnaNo = receivedData?.qnaNo;
    const [data, setData] = useState([]);
    const [rcvData, setRcvData] = useState([]);
    const [notice, setNotice] = useState('Y');


    useEffect(() => {
    //   getData();
      postData();
    }, []);

    async function getData() {   
            
        // try{           
        //     const ret = await axios.get(
        //         Config.API_SERVER+"site",
        //         { headers: { 
        //             Authorization: "Bearer "+userInfo.jwtToken,
        //             },
        //         }
        //     );
        //     if(ret?.data?.data){
        //         // console.log("data : "+JSON.stringify(ret.data.data ));
        //         setFilterFrom({
        //             date: dayjs(ret.data.data.pbDrwDt),
        //             time: dayjs(ret.data.data.pbDrwTm, "HHmm"),
        //         });
        //         setFilterTo({
        //             date: dayjs(ret.data.data.mgDrwDt),
        //             time: dayjs(ret.data.data.mgDrwTm, "HHmm"),
        //         });
        //         // setData(ret.data.data);
        //         setSiteDetail(ret.data.data);
        //         setDrwDdlTmTemp(ret.data.data.drwDdlTm.slice(0,2)+":"+ret.data.data.drwDdlTm.slice(2,4));
        //         setScanTmTemp(ret.data.data.scanTm.slice(0,2)+":"+ret.data.data.scanTm.slice(2,4));
        //     }
        // }catch (error) {
        //     console.error("Error getData:", error);
        // }
    }

    async function postData() {   
            console.log("111");
        try{        
            const data = {
                "storeNo": 4,
                "giftNo": 16,
                "gifterNo": 2,
                "gifterMobile": "010-1111-2222",
                "gifterEmail": "naegift-user1@authrium.com",
                // "gifteeNo": 5,
                "gifteeMobile": "010-4444-5555",
                "gifteeEmail": "naegift-user3@authrium.com",
                "memo": "포스트 구매 테스트에요~",
                "sendMethod": "SM01",
                "payMethod": "PM01",
                "pgId": 1,
                "pgCode": "5983058359385iodutodt598593IUYUYU"
            };
            // console.log(" data : "+JSON.stringify(data));
            const jwtToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJObyI6MiwidXNlck5vIjowLCJpYXQiOjE3MTAzOTc3NzIsImV4cCI6MTcxMDQwMTM3Mn0.wS31ZOgQNal9K9I_2JUD0f0JpcJQTYB5PZBNpTrg760";
            const ret = await axios.post(`${Config.API_SERVER}purchase`,
                data,
                { headers: { 
                    Authorization: "Bearer "+jwtToken,
                    },
                }
            );
            // console.log("API SERVER : "+Config.API_SERVER);
            // const ret = await axios.get(`${Config.API_SERVER}gift`,
            //     { 
            //         // headers: { 
            //         //     Authorization: "Bearer "+jwtToken,
            //         // },
            //     }
            // );
        //     const ret = await axios.get(
        //         Config.API_SERVER+"site",
        //         { headers: { 
        //             Authorization: "Bearer "+userInfo.jwtToken,
        //             },
        //         }
        //     );


            console.log("data : "+JSON.stringify(ret.data.data));
            setRcvData(JSON.stringify(ret.data.data));
            // if(ret?.data?.data){
            //     console.log("data : "+JSON.stringify(ret.data.data ));
                // setFilterFrom({
                //     date: dayjs(ret.data.data.pbDrwDt),
                //     time: dayjs(ret.data.data.pbDrwTm, "HHmm"),
                // });
                // setFilterTo({
                //     date: dayjs(ret.data.data.mgDrwDt),
                //     time: dayjs(ret.data.data.mgDrwTm, "HHmm"),
                // });
                // // setData(ret.data.data);
                // setSiteDetail(ret.data.data);
                // setDrwDdlTmTemp(ret.data.data.drwDdlTm.slice(0,2)+":"+ret.data.data.drwDdlTm.slice(2,4));
                // setScanTmTemp(ret.data.data.scanTm.slice(0,2)+":"+ret.data.data.scanTm.slice(2,4));
            // }
        }catch (error) {
            console.error("Error getData:", error);
        }
    }


    
    return (
    <>
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>테스트페이지</li>
                <li>테스트</li>
            </ul>
        </section>

        <main>
            <section className="contants_box contants_box_mypage">

                <LeftMenu pageName='qna'/> 

                <section className="rightcontant_box">
                    <h2 className="tit_01">테스트</h2>
                    <h2 className="tit_01">결과 : </h2>
                    <h2 className="tit_01">{rcvData} </h2>
                </section>

            </section>
        </main>
    </>
    );
}