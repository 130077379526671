import React from "react";


export function getCurrencyString(num) {
  // console.log(num+" ===> "+Intl.NumberFormat().format(parseInt(num)));
  // console.log(num+" ===> "+Intl.NumberFormat().format(num));
  // return new Intl.NumberFormat().format(num);
  // return new Intl.NumberFormat('ko-KR', { maximumSignificantDigits: 3 }).format(num);
  return new Intl.NumberFormat().format(num, {
    style: "currencyWithGroupingSeparator",
  });
}

export function formatStrNumber(value) {
  // 숫자가 아닌 문자 제거
  const formattedValue = value.replace(/[^0-9]/g, '');
  
  // 3자리마다 콤마 추가
  const numberWithCommas = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
  return numberWithCommas;
};


// 숫자 형식 가공 및 콤마 추가 함수
export function formatStrFloat(value) {
  const stringValue = String(value);  // 문자열로 변환

  const parts = stringValue.split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  let decimalPart = parts[1] ? `.${parts[1]}` : '';
  if(stringValue.charAt(stringValue.length - 1) === '.'){
    decimalPart = '.';
  }  
  
  return `${integerPart}${decimalPart}`;
};

export function formatNumNumber(value) {

  // 숫자가 아니면 빈 문자열 반환
  // console.log("========= "+value);
    if (value === undefined || value === null) {
      return '0';
    }
    // 숫자가 아닌 문자 제거
    const formattedValue = value.toString().replace(/[^0-9]/g, '');
    // console.log("formattedValue : "+formattedValue);
    
    // 3자리마다 콤마 추가
    const numberWithCommas = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // console.log("numberWithCommas : "+numberWithCommas);

    return numberWithCommas;
};


export function formatNumber(value) {

  // 숫자가 아니면 빈 문자열 반환
  // console.log("========= "+value);
    if (value === undefined || value === null) {
      return '0';
    }
    // 숫자가 아닌 문자 제거
    const formattedValue = value.toString().replace(/[^0-9]/g, '');
    // console.log("formattedValue : "+formattedValue);

    return formattedValue;
};

export function  formatFloatNumber(value, totalDigits) {
  // console.log("Original value:", value);
    if (value === undefined) {
    return '0';
    }
    
    const formattedValue = value.replace(/[^\d.]/g, '');
    // console.log("Formatted value (after removing non-digits and non-decimals):", formattedValue);

    const parts = formattedValue.split('.');
    // console.log("Parts:", parts);

    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    let decimalPart = '';
    if (parts[1] !== undefined) {
      // 소수점 이하 totalDigits만큼만 유지
      decimalPart = `.${parts[1].slice(0, totalDigits)}`;
    }

    // console.log("Integer part:", integerPart);
    // console.log("Decimal part:", decimalPart);

    return `${integerPart}${decimalPart}`;
};

export function delComString(str) {
  // console.log("=== TEST ["+temp+"]  변환 후 ["+temp.split(',').replace(/,/g, '')+"]");
  // return str.split(',').replace(/,/g, '');
  const isNumber = /^\d+$/.test(str);
  if(isNumber){
    return str;
  }else{
    return str.replaceAll(',', '');
  }
}

export function getFormattedDateTime(rawDateTime) {
  // 현재 날짜 및 시간 가져오기
  const currentDate = new Date(rawDateTime);

  // 년, 월, 일 가져오기
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1, 두 자리로 만들기
  const day = String(currentDate.getDate()).padStart(2, '0'); // 두 자리로 만들기

  // 시, 분, 초, 밀리초 가져오기
  const hours = String(currentDate.getHours()).padStart(2, '0'); // 두 자리로 만들기
  const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // 두 자리로 만들기
  const seconds = String(currentDate.getSeconds()).padStart(2, '0'); // 두 자리로 만들기
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0'); // 세 자리로 만들기

  // 포맷된 문자열 생성
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

  // console.log(formattedDateTime);
  return formattedDateTime;
}