import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
// import { getPostcode } from '../../components/zip.html';


export default function UserContent(props) {

    useEffect(() => {
      
    }, []);
  
    return (
    <>
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>회원가입</li>
                <li>01.관리자 정보</li>
            </ul>
        </section>

        <main>
            <section className="contants_box contants_box_02">
                <h2 className="tit_01">회원가입</h2>

                <article className="signup_03">
                    <h3 className="tit_02">01.관리자 정보</h3>
                    <p>내기프트 서비스 이용을 위해 관리자 정보를 입력 해 주세요.</p>
                </article>

                <article className="contants_box_03">
                    <table className="member_box">
                        <tbody>
                            <tr>
                                <th>아이디</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="text" name="" placeholder="영문 소문자, 숫자를 포함한 5~15자 이내" />
                                        <button type="button" className="button_05 button_gray">중복확인</button>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>비밀번호</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="password" name="" placeholder="영문, 숫자, 특수문자 조합 8~16자" />
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>비밀번호 확인</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="password" name="" placeholder="영문, 숫자, 특수문자 조합 8~16자" />
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="text" name="" />
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="date" name="" />
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>성별</th>
                                <td>
                                    <form>
                                        <select className="input_01">
                                            <option value="선택">선택</option>
                                            <option value="남자">남자</option>
                                            <option value="여자">여자</option>
                                        </select>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="text" name="" placeholder="예)naegift@authrium.com" />
                                        <button type="button" className="button_05 button_gray">인증번호받기</button>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>이메일 인증번호</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="text" name="" />
                                        <button type="button" className="button_05 button_gray">인증하기</button>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰번호</th>
                                <td>
                                    <form>
                                        <input className="input_02" type="text" name="" />-
                                        <input className="input_02" type="text" name="" />-
                                        <input className="input_02" type="text" name="" />
                                        <button type="button" className="button_05 button_gray">인증번호받기</button>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰번호 인증번호</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="text" name="" />
                                        <button type="button" className="button_05 button_gray">인증하기</button>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td> TEST
                                    {/* <div className="signup_04"> */}
                                        {/* <form>
                                            <input className="input_01" type="text" name="" disabled />
                                            <button type="button" className="button_05 button_gray">찾기</button>
                                            <br/>
                                            <input className="input_03" type="text" name="" disabled /><br/>
                                            <input className="input_03" type="text" name="" />
                                        </form> */}
                                        {/* <input type="text" id="sample4_postcode" placeholder="우편번호"/>
                                        <input type="button" onclick="getPostcode()" value="우편번호 찾기"/>
                                        <input type="text" id="sample4_roadAddress" placeholder="도로명주소"/>
                                        <input type="text" id="sample4_jibunAddress" placeholder="지번주소"/>
                                        <span id="guide" style="color:#999;display:none"></span>
                                        <input type="text" id="sample4_detailAddress" placeholder="상세주소"/>
                                        <input type="text" id="sample4_extraAddress" placeholder="참고항목"></input> */}
                                    {/* </div> */}
                                </td>
                            </tr>
                            <tr>
                                <th>SMS/푸시/이메일 수신동의</th>
                                <td>
                                    <div className="signup_05">
                                        <label className="check_box">수신동의
                                            <input type="checkbox" checked="checked" />
                                            <span className="check_radio"></span>
                                        </label>
                                        <label className="check_box">수신거부
                                            <input type="checkbox" />
                                            <span className="check_radio"></span>
                                        </label>
                                        <p className="font_gray">※ 내기프트 서비스를 이용하기 위해서는 SMS/푸시/이메일 수신을 동의하셔야 합니다. </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </article>

                <article className="button_box_01">
                    <NavLink to='/user-signup'>
                        <button type="button" className="button_01 button_03 button_gray_line">뒤로</button>
                    </NavLink>
                    <NavLink to='/store-content'>
                        <button type="button" className="button_01 button_03 button_red">다음</button>
                    </NavLink>
                </article>

            </section>
        </main>
    </>
    );
}