import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';


export default function IdSearch(props) {

    useEffect(() => {
      
    }, []);
  
    return (
    <>
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>아이디 찾기</li>
            </ul>
        </section>

        <main>
            <section className="contants_box_04">

                <article className="signup_07">
                    <h2 className="tit_01">아이디 찾기</h2>
                    <small>아이디는 가입 시 입력하신 이메일을 통해 찾을 수 있습니다.</small>
                </article>

                <article className="contants_box_03">
                    <table className="login_box">
                        <tbody>
                            <tr>
                                <th>이름</th>
                                <td><input className="input_03" type="text" name="" /></td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td><input className="input_03" type="text" name="" /></td>
                            </tr>
                        </tbody>
                    </table>
                </article>

                <div className="signup_08">
                    <article className="button_box_01">
                        <NavLink to='/login'>
                            <button type="button" className="button_01 button_07 button_gray_line">로그인</button>
                        </NavLink>
                        <NavLink to='/login-idsearch-done'>
                            <button type="button" className="button_01 button_07 button_red">아이디 찾기</button>
                        </NavLink>
                    </article>
                </div>

            </section>
        </main>
    </>
    );
}