import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import { userLogin } from "../../reducers/userInfo";
import { useSelector, useDispatch } from "react-redux";
import { Config } from "../../common/config";
import axios from "axios";

export default function UserWithdrawal(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.userInfo);
    // console.log("userInfo : "+JSON.stringify(userInfo));

    const [modalOpen, setModalOpen] = useState(false);   //모달 상태 관리 : 기본값 - 닫힘
    const [modalInfo, setModalInfo] = useState({isCancel:true, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'', action:'', isPercent:false});
    const [data, setData] = useState([]);

    const openModal = (e) => {
        // console.log("openModal actionFlag:"+modalInfo.actionFlag);
            if(modalInfo.actionFlag === 'del'){
                // console.log("===== del");
                setModalInfo({...modalInfo, actionFlag:'del', mainMsg:'정말 탈퇴하시겠습니까?\n탈퇴 후 정보 복구가\n불가능합니다.'});
            }
            setModalOpen(true);
     };
     
     const closeModal = () => {
         setModalOpen(false);
        //  if(modalInfo.actionFlag === 'list'){
        //    getData();   
        // } 
        navigate('/user-manage');
     };
 
     const doModal = () => {
        // console.log("doModal actionFlag:"+modalInfo.actionFlag);
        setModalOpen(false);
        if(modalInfo.actionFlag === 'del'){
            delUser();
        }else if(modalInfo.actionFlag === 'goMain'){
            navigate('/go-main');
        }
     };

     const refetchJwt = async() => {
    
      try{
      const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
        userId: userInfo.id,
        pwd:userInfo.pwd
      })
    
      console.log("jwt재발급: ",response.data.data.accessToken)
      const jwtToken = response.data.data.accessToken;
      const user ={ 
        ...userInfo,
        jwtToken: jwtToken
      }
      console.log(user);
      dispatch(userLogin(user));
      delUser(jwtToken);
     
    }catch(e){
      console.log(e);
    }
    }

     const delUser = async(newToken) => {
        // console.log("delUser");

        try{
            
            const ret = await axios.delete(`${Config.API_SERVER}user`,
                                { headers: { 
                                  Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                                    },
                                }
                            );

            // URL 생성
            const url = `${Config.SHOPMAIN_SERVER}`;
    
            // URL 이동
            window.location.href = url;

            // navigate('/');

        } catch (error) {
            // 에러 발생 시의 코드
            // console.error("Error fetching data : ", JSON.stringify(error));         
            if (error.response) {
              if (error.response.data.resultCode === 401) {
                const autologin = localStorage.getItem("autologin");
                console.log(autologin);
                if(autologin){
                  refetchJwt();                
                }else{
                modalInfo.actionFlag = 'login';
                modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
                setModalOpen(true);
                }
              }     
            } else if (error.request) {
                // 요청은 전송되었지만 응답이 없는 경우
                // alert("No response received from the server.");
                modalInfo.actionFlag = 'api';
                openModal();
            } else {
                // 요청을 설정하는 중에 에러가 발생한 경우
                // alert("Error setting up the request.");
                modalInfo.actionFlag = 'api';
                openModal();
            }
            console.error("Error fetching data:", error);
        } 
       
    };

    useEffect(() => {
      
    }, []);
  
  
    return (
    <>
        <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
            isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
            {modalInfo.mainMsg}
        </AlertModal>

        <section class="location_box">
            <ul class="location_001">
                <li>홈</li>
                <li>마이페이지</li>
                <li>관리자 정보 관리</li>
                <li>회원탈퇴</li>
            </ul>
        </section>

        <main>
            <section class="contants_box contants_box_mypage">

                <LeftMenu pageName='user'/>

                <section class="rightcontant_box">
                    <h2 class="tit_01">회원탈퇴</h2>

                    <main>
                        <section class="contants_box_04">
                            <article class="signup_07">
                                <h2 class="tit_01">회원탈퇴란,<br/>내기프트에 대한 이용해지를 의미합니다.</h2><br/>
                            </article>

                            <article class="contants_box_03">
                                <div class="signup_07_001">
                                    &ensp;&ensp;<span style={{color: 'blue', fontSize: '18px'}}>- 회원탈퇴 시 유의사항</span><br/>
                                    &ensp;&ensp;회원탈퇴를 위해서는 아래 조건에 대한 확인이 필요합니다.<br/><br/>
                                    <p class="signup_07_002 font_red">1. 등록하신 상점 기프트 QR코드는 사용이 불가능해집니다.</p>
                                    <p class="signup_07_002 font_red">2.고객들이 보유한 기프트는 더 이상 사용이 불가능해집니다.</p>
                                    <p class="signup_07_002 font_red">3.예치 금액은 기프트 구매자에게 환불됩니다.</p>  
                                    <p class="signup_07_002 font_red">4. 동일한 아이디로 재가입은 불가능하며 새로운 아이디로만 재가입이 가능합니다.</p>
                                </div>
                            </article>
                            
                            <div><br/> <br/></div>
                            <article class="button_box_01">
                                {/* <button type="button" class="button_01 button_gray_line" onclick="location.href='admin_manage.html'">취소</button> */}
                                <button type="button" class="button_01 button_03 button_red" 
                                    onClick = {(e) => {
                                        modalInfo.actionFlag = 'del';
                                        openModal(e);
                                    }}>회원탈퇴 신청</button>
                            </article>
                        </section>
                    </main>

                </section>

            </section>
        </main>
    </>
    );
}