import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { userLogin, userLogout } from "../reducers/userInfo";
import { Config } from "../common/config";

import naegift_ko_logo from "../images/naegift_ko_logo.png"


export default function GoMain() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const navigate = useNavigate();

    const logOut = () => {// 로그인 정보 존재할 때
        dispatch(userLogout());
        goLogout();
        // navigate('/')
    }
    useEffect(() => {
        logOut();
    }, []);

    return (
 <>{}</>
    );
}