import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import axios from "axios";
import { Config } from "../../common/config";
import { useSelector,useDispatch } from "react-redux";
import { userLogin } from "../../reducers/userInfo";

export default function QnaContent(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const receivedData = location.state;
    // console.log("receivedData : "+JSON.stringify(receivedData));
    const qnaNo = receivedData?.qnaNo;
    const [data, setData] = useState([]);
    const [notice, setNotice] = useState('Y');
    const userInfo = useSelector((state) => state.userInfo);
    const [modalInfo, setModalInfo] = useState({isCancel:true, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'삭제하시겠습니까?', action:'', isPercent:false});
    const [modalOpen, setModalOpen] = useState(false);
    useEffect(() => {
      getData();
    }, []);

    const refetchJwt = async(refetchmod) => {
    
      try{
      const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
        userId: userInfo.id,
        pwd:userInfo.pwd
      })
  
      console.log("jwt재발급: ",response.data.data.accessToken)
      const jwtToken = response.data.data.accessToken;
      const user ={ 
        ...userInfo,
        jwtToken: jwtToken
      }
      console.log(user);
      dispatch(userLogin(user));
      if(refetchmod=="DEL_QNA"){
        deleteQNA(jwtToken);
      }else{
        getData(jwtToken);
      }
    
    }catch(e){
      console.log(e);
    }
    }

    async function deleteQNA(newToken) {   
      console.log(qnaNo)
      try{           
        const ret = await axios.delete(
             Config.API_SERVER+`qna/${qnaNo}`,
           { headers: { 
            Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                 },
             }
         );
         navigate("/qna-list")
        
     }catch (error) {
      if (error.response.data.resultCode === 401) {
        const autologin = localStorage.getItem("autologin");
        console.log(autologin);
        if(autologin){
          refetchJwt("DEL_QNA");                
        }else{
        //modalInfo.actionFlag = 'login';
        //modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
        setModalInfo({actionFlag: 'login', mainMsg: "유효기간이 만료되었으니 다시 로그인해주세요"})
        setModalOpen(true);
        }
      }
         console.error("Error getData:", error);
     }
}
const openModal = () => {
    setModalOpen(true);
    setModalInfo({...modalInfo, actionFlag:"del"});
}
const closeModal = () => {
setModalOpen(false);
};
const doModal = () => {
  setModalOpen(false);
  if(modalInfo.actionFlag === "login"){
    navigate('/login');
  }else{
    deleteQNA();
  }
};

    async function getData(newToken) {   
            
         try{           
            const ret = await axios.get(
                 Config.API_SERVER+`qna/${qnaNo}`,
               { headers: { 
                Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                     },
                 }
             );
             setData(ret.data.data);           
         }catch (error) {
          if (error.response.data.resultCode === 401) {
            const autologin = localStorage.getItem("autologin");
            console.log(autologin);
            if(autologin){
              refetchJwt("GET_DATA");                
            }else{
            modalInfo.actionFlag = 'login';
            modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
            setModalOpen(true);
            }
          }
             console.error("Error getData:", error);
         }
    }

    
    return (
    <>
    <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
            isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
            {modalInfo.mainMsg}
        </AlertModal>
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>마이페이지</li>
                <li>서비스 문의</li>
            </ul>
        </section>

        <main>
            <section className="contants_box contants_box_mypage">

                <LeftMenu pageName='qna'/> 

                <section className="rightcontant_box">
                    <h2 className="tit_01">서비스 문의</h2>

                    <article className="contants_box_03">
                        <table className="member_box">
                            <tbody>
                                <tr>
                                    <th>구분</th>
                                    <td>{data ? data.qnaCategoryeDesc : " "}</td>
                                </tr>
                                <tr>
                                    <th>작성자</th>
                                    <td>{data ? data.userId : " "}</td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td>{data ? data.title : " "}</td>
                                </tr>
                                <tr>
                                    <th>내용</th>
                                    <td>
                                        <div className="table_05">{data ? data.qContent : " "}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>답변</th>
                                    <td>
                                        <div className="table_05">{data ? data.aContent : " "}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>파일 다운로드</th>
                                    <td>{data ? data.fileUrl : " "}</td>
                                </tr>
                                <tr>
                                    <th>공개유형</th>
                                    <td>{data.noticeYn==="Y" ? "공개" : "비공개"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </article>

                    <article className="button_box_01">
                    <NavLink to='/qna-list'>
                            <button type="button" className="button_01 button_03 button_gray_line">목록</button>
                        </NavLink>
                        
                            <button type="button" onClick={()=>navigate("/qna-modify" ,{state: {qnaNo: qnaNo}})} className="button_01 button_03 button_red">수정</button>
                       
                        
                            <button type="button" onClick={openModal} className="button_01 button_03 button_gray_line font_red">삭제</button>
                       
                    </article>

                </section>

            </section>
        </main>
    </>
    );
}