import React from 'react';
import { NavLink } from 'react-router-dom';


export default function LeftMenu(props) {
   const pageName =  props.pageName;

    return (
    <>
        <section className="leftmenu_box">
            <h2 className="tit_01">마이페이지</h2>
            <nav>
                <ul className="leftmenu_01">
                    <li><NavLink className={pageName === 'gift' ? 'select' : ''} to='/gift-list'>기프트 관리</NavLink></li>
                    <li><NavLink className={pageName === 'user' ? 'select' : ''} to='/user-manage'>관리자 정보 관리</NavLink></li>
                    <li><NavLink className={pageName === 'store' ? 'select' : ''} to='/store-manage'>상점 정보 관리</NavLink></li>
                    <li><NavLink className={pageName === 'qna' ? 'select' : ''} to='/qna-list'>서비스 문의</NavLink></li>
                    {/* <li><NavLink className={pageName === 'testPage' ? 'select' : ''} to='/test'>테스트 페이지</NavLink></li> */}
                </ul>
            </nav>
        </section>
    </>
    );
}