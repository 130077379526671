import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import QRCode from 'qrcode.react';


export default function StoreContent(props) {
    const [qrValue, setQRValue] = useState('');
    // let qrValue = '';

    useEffect(() => {
      
    }, [qrValue]);
  
    return (
    <>
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>회원가입</li>
                <li>01.관리자 정보</li>
                <li>02.상점 정보</li>
            </ul>
        </section>

        <main>
            <section className="contants_box contants_box_02">
                <h2 className="tit_01">회원가입</h2>

                <article className="signup_03">
                    <h3 className="tit_02">02.상점 정보</h3>
                    <p>내기프트 서비스 이용을 위해 상점정보를 입력하고 선물하기 QR코드를 생성해 주세요.<br/>개인의 경우는 기프트를 발행할 가상의 상점정보을 설정하시기 바랍니다.</p>
                </article>

                <article className="contants_box_03">
                    <table className="member_box">
                        <tbody>
                            <tr>
                                <th>상점명</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="text" name="" />
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>상점 로고/사진</th>
                                <td>
                                    <form>
                                        <input type="file" name="" />
                                    </form>
                                    <p className="font_gray">※ jpg,jpeg,gif,png 파일 첨부 가능, 1개 파일을 3MByte까지 업로드 할 수 있습니다.</p>
                                </td>
                            </tr>
                            <tr>
                                <th>전화번호</th>
                                <td>
                                    <form>
                                        <input className="input_02" type="text" name="" />-
                                        <input className="input_02" type="text" name="" />-
                                        <input className="input_02" type="text" name="" />
                                        <label className="check_box">관리자 정보와 동일
                                            <input type="checkbox" checked="checked" />
                                            <span className="check_check"></span>
                                        </label>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td>
                                    <div className="signup_04">
                                        <form>
                                            <input className="input_01" type="text" name="" disabled />
                                            <button type="button" className="button_05 button_gray">찾기</button>
                                            <br/>
                                            <input className="input_03" type="text" name="" disabled /><br/>
                                            <input className="input_03" type="text" name="" />
                                        </form>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>입금받을 은행</th>
                                <td>
                                    <form>
                                        <select className="input_01">
                                            <option value="계좌선택">은행선택</option>
                                            <option value="우리은행">우리은행</option>
                                            <option value="국민은행">국민은행</option>
                                        </select>
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>입금받을 계좌번호</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="text" name="" />
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>예금주</th>
                                <td>
                                    <form>
                                        <input className="input_01" type="text" name="" />
                                    </form>
                                </td>
                            </tr>
                            <tr>
                                <th>선물하기 QR 코드</th>
                                <td>
                                    <div className="signup_06">
                                        <div className="signup_06_002">
                                            {qrValue !== '' &&
                                                <QRCode value={qrValue} />
                                            }
                                        </div>
                                        <div className="button-container">
                                            <button type="button" className="button_05 button_blue" 
                                                onClick= {(e) => {
                                                    setQRValue('http://www-dev.naegift.com:3000/giftshop-kor-4');                                                    
                                                }}>선물하기 QR코드 생성하기</button>
                                            <button type="button" className="button_05 button_black">선물하기 QR코드 저장하기</button>
                                            <button type="button" className="button_05 button_gray">선물하기 QR코드 프린트하기</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </article>

                <article className="button_box_01">
                    <NavLink to='/user-content'>
                        <button type="button" className="button_01 button_03 button_gray_line">뒤로</button>
                    </NavLink>
                    <NavLink to='/user-confirm'>
                        <button type="button" className="button_01 button_03 button_red">회원가입</button>
                    </NavLink>
                </article>

            </section>
        </main>
    </>
    );
}