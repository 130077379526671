import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Config } from "../../common/config";
import { userLogin } from "../../reducers/userInfo";

export default function QnaModify() {
  const MAX_TITLE_LENGTH = 80;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);   //모달 상태 관리 : 기본값 - 닫힘
    const [modalInfo, setModalInfo] = useState({isCancel:true, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'수정하시겠습니까?', action:'', isPercent:false});
    const [data, setData] = useState([]);
    const [notice, setNotice] = useState('Y');
    const userInfo = useSelector((state) => state.userInfo);
    const {qnaNo} = location.state;
    console.log(qnaNo)
    const [formValues, setFormValues] = useState({
      category : '',
      userNo : userInfo.userNo,
      storeNo :userInfo.storeNo,
      title : '',
      qContent : '',
      aContent : '',
      file: null,
      notice :''
  });
  console.log(formValues)
  const openModal = () => {
    if (!formValues.category || !formValues.title || !formValues.qContent) {
        // 필수 입력값이 없는 경우 모달창을 열고 반환
        setModalInfo({
            ...modalInfo,
            headerMsg: '알림',
            mainMsg: '모든 필수 입력값을 입력해주세요.',
            actionFlag: "validate",
        });
        setModalOpen(true);
        return;
      }
      setModalOpen(true);
  }
  const closeModal = () => {
  setModalOpen(false);
};
const doModal = () => {
  if(modalInfo.actionFlag === "login"){
    navigate("/login")
  }
  handleQnaModSubmit();
};

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
        ...formValues,
        [name]: value
    });
};
const handleFileChange = (event) => {
  setFormValues({
      ...formValues,
      file: event.target.files[0]
  });
}
const formData = new FormData();
formData.append('qnaCategory', formValues.category);
formData.append('userNo', formValues.userNo);
formData.append('title', formValues.title);
formData.append('qContent', formValues.qContent);
if (formValues.file) {
  formData.append('file', formValues.file);
}
formData.append('noticeYn', formValues.notice);

const refetchJwt = async(refetchmod) => {
    
  try{
  const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
    userId: userInfo.id,
    pwd:userInfo.pwd
  })

  console.log("jwt재발급: ",response.data.data.accessToken)
  const jwtToken = response.data.data.accessToken;
  const user ={ 
    ...userInfo,
    jwtToken: jwtToken
  }
  console.log(user);
  dispatch(userLogin(user));
  if(refetchmod=="GET_DATA"){
    getQnaContent(jwtToken);
  }else{
    handleQnaModSubmit(jwtToken);
  }
 
}catch(e){
  console.log(e);
}
}

const handleQnaModSubmit = async (newToken) => {

  try {
      const response = await axios.put(
          `${Config.API_SERVER}qna/${qnaNo}`, // 여기에 API 엔드포인트 URL을 입력하세요
          formData, // 요청 본문에 formValues 추가
          {
            headers: { 
              Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
              },
          }
      );

      // 성공적으로 등록되었을 때의 작업
      console.log('수정 성공:', response.data);

      navigate('/qna-list');
      
  } catch (error) {
    if (error.response.data.resultCode === 401) {
      const autologin = localStorage.getItem("autologin");
      console.log(autologin);
      if(autologin){
        refetchJwt("MOD_DATA");                
      }else{
     // modalInfo.actionFlag = 'login';
      //modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
      setModalInfo({...modalInfo, actionFlag: "login", mainMsg:"유효기간이 만료되었으니 다시 로그인해주세요"})
      setModalOpen(true);
      }
    }     
      // 등록 중 오류 발생 시의 작업
      console.error('수정 오류:', error);
      // 오류 메시지를 모달로 표시하거나 다른 방법으로 사용자에게 알림
  }
};

const getQnaContent = async(newToken) =>{
  try {
    const response = await axios.get(
        `${Config.API_SERVER}qna/${qnaNo}`, // 여기에 API 엔드포인트 URL을 입력하세요
        {
          headers: { 
            Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
            },
        }
    );
    setFormValues( {
    category : response.data.data.qnaCategory,
    userNo : userInfo.userNo,
    storeNo :userInfo.storeNo,
    title : response.data.data.title,
    qContent : response.data.data.qContent,
    aContent : response.data.data.aContent,
    //file: response.data.data.fileUrl,
    notice :  response.data.data.noticeYn
  })
    console.log(response.data.data)
} catch (error) {
  if (error.response.data.resultCode === 401) {
    const autologin = localStorage.getItem("autologin");
    console.log(autologin);
    if(autologin){
      refetchJwt("GET_DATA");                
    }else{
    //modalInfo.actionFlag = 'login';
    //modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
    setModalInfo({...modalInfo, actionFlag: "login", mainMsg:"유효기간이 만료되었으니 다시 로그인해주세요"})
    setModalOpen(true);
    }
  }     
    console.error('수정데이터를 가져오지 못했습니다:', error);
}

}

    useEffect(() => {
      getQnaContent();
    }, []);
 
    return (
    <>
        <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
            isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
            {modalInfo.mainMsg}
        </AlertModal>
       

        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>마이페이지</li>
                <li>서비스 문의</li>
            </ul>
        </section>

        <main>
            <section className="contants_box contants_box_mypage">

                <LeftMenu pageName='qna'/> 

                <section className="rightcontant_box">
                    <h2 className="tit_01">서비스 문의</h2>

                    <article className="contants_box_03">
                        <table className="member_box">
                            <tbody>
                                <tr>
                                    <th>구분</th>
                                    <td>
                                        <form>
                                        <select className="input_01" name="category" value={formValues.category} onChange={handleInputChange}>
                                                <option value="QA01">서비스 관련</option>
                                                <option value="QA02">기프트 발행</option>
                                                <option value="QA03">기프트 구매선물</option>
                                                <option value="QA04">기프트 사용검증</option>
                                                <option value="QA05">입금</option>
                                                <option value="QA06">환불</option>
                                                <option value="QA07">기타</option>
                                            </select>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>작성자 *</th>
                                    <td>
                                        <form>
                                            <input className="input_01" name="writer" value={userInfo.id}  type="text" disabled />
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목 *</th>
                                    <td>
                                        <form className='form_01'>
                                        <input className="input_03" type="text" name="title" value={formValues.title} maxLength={80} onChange={handleInputChange} />
                                        <label className="label_01">{formValues.title.length}/{80}</label>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>내용 *</th>
                                    <td>
                                        <form>
                                        <textarea className="textarea_box" id="" name="qContent" rows="" cols="" value={formValues.qContent} onChange={handleInputChange}></textarea>
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <th>파일 업로드</th>
                                    <td>
                                        <form>
                                        <input type="file" name="file" onChange={handleFileChange} />
                                        </form>
                                        <p className="font_gray">※ jpg,jpeg,gif,png 파일 첨부 가능, 1개 파일을 3MByte까지 업로드 할 수 있습니다.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>공개유형</th>
                                    <td>
                                        <div className="signup_05">
                                            <label className="check_box">
                                                <input
                                                type="radio"
                                                name="notice"
                                                value="Y"
                                                checked={formValues.notice === 'Y'}
                                                onChange={handleInputChange}
                                                />
                                                공개
                                                <span className="check_radio"></span>
                                            </label>
                                            <label className="check_box">
                                                <input
                                                type="radio"
                                                name="notice"
                                                value="N"
                                                checked={formValues.notice === 'N'} 
                                                onChange={handleInputChange}
                                                />
                                                비공개
                                                <span className="check_radio"></span>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </article>

                    <p className="table_04 font_gray">※ 고객님의 소중한 의견을 수렴하는 공간입니다. 내기프트에 바라는 내용을 등록해 주세요.<br/>상업적 글이나 무고, 욕설, 비방, 개인정보 등과 관련된 글을 올리는 경우 삭제 될 수 있습니다.</p>
                    <article className="button_box_01">
                        <NavLink to='/qna-list'>
                            <button type="button" className="button_01 button_03 button_gray_line">취소</button>
                        </NavLink>
                        <button type="button" onClick={openModal} className="button_01 button_03 button_red" >수정</button>
                    </article>

                </section>

            </section>
        </main>
    </>
    );
}