import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import dayjs from 'dayjs'

import LeftMenu from '../../components/leftMenu';
import AlertModal from '../../components/alertModal';
import Pagination from '../../components/pagination';
import { Config } from "../../common/config";
import { useSelector, useDispatch } from "react-redux";
import { userLogin } from "../../reducers/userInfo";

export default function QnaList(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const receivedData = location.state;
    //  console.log("receivedData : "+JSON.stringify(receivedData));
    //  const mode = receivedData?.mode;
    const userInfo = useSelector((state) => state.userInfo);
    const [filterFrom, setFilterFrom] = useState(dayjs());
    const [filterTo, setFilterTo] = useState(dayjs());
    const [searchCondition, setSearchCondition] = useState({searchSection: '', searchTake: Config.PAGE_SIZE_LIST[0], searchFrom: dayjs(filterFrom).format('YYYY-MM-DD'), searchTo: dayjs(filterTo).format('YYYY-MM-DD')});
    // console.log("------------------------------ searchCnd : ", JSON.stringify(searchCondition));
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState({isCancel:false, isConfirm:true, isMessage:true, headerMsg:'', actionFlag:'', mainMsg:'', action:'', isPercent:false});   
    let selectedQnaNo ='';

    const goQna = (qnaNo) => {
        console.log("goQna");
        const passData = {
            qnaNo: qnaNo,
        };
        // console.log("passData", JSON.stringify(passData));
        navigate('/qna-content', {state: passData});
    };

    
    const refetchJwt = async() => {
    
      try{
      const response = await axios.post(`${Config.API_SERVER}auth/getuser`, {
        userId: userInfo.id,
        pwd:userInfo.pwd
      })
  
      console.log("jwt재발급: ",response.data.data.accessToken)
      const jwtToken = response.data.data.accessToken;
      const user ={ 
        ...userInfo,
        jwtToken: jwtToken
      }
      console.log(user);
      dispatch(userLogin(user));
        getData(jwtToken);
    }catch(e){
      console.log(e);
    }
    }

    useEffect(() => {
        getData();
    }, [currentPage, searchCondition.searchTake]);
  
    async function getData(newToken) {   
         try{
             const ret = await axios.get(
                Config.API_SERVER+"qna"+
                "?pageNo="+currentPage+
               "&pageSize="+searchCondition.searchTake,
                     { headers: { 
                      Authorization: `Bearer ${newToken? newToken : userInfo.jwtToken}`,
                         },
                     }
             );
             console.log(ret.data.data.list);

             if(ret?.data?.data){
                 const count = ret.data.data.totalCount;
                  console.log("Count : "+count);
                 const tp = Math.ceil(count / searchCondition.searchTake);
                  console.log("tp : "+tp);

                 setTotalPage(tp);
                 setTotal(count);
                 // console.log("List : "+JSON.stringify(ret.data.data.qnaList));
                 setData(ret.data.data.list);
             }
         }catch (error) {
          if (error.response.data.resultCode === 401) {
            const autologin = localStorage.getItem("autologin");
            console.log(autologin);
            if(autologin){
              refetchJwt();                
            }else{
            //modalInfo.actionFlag = 'login';
            setModalInfo({...modalInfo, mainMsg:"유효기간이 만료되었으니 다시 로그인해주세요", actionFlag: 'login'});
           
            //modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
            setModalOpen(true);
            }
          }
             console.error("Error getData:", error);
         }
    }
    console.log(data);
    const pageChange = (page) => {
         console.log(`Page changed to ${page}`);
        setCurrentPage(page);        
    }
    const closeModal = () => {
      // console.log("closeModal");
      if(modalInfo.actionFlag === 'del'){
          modalInfo.isCancel = false ;
      }

      setModalOpen(false);
   };
    const doModal = () => {

      setModalOpen(false);        
     if(modalInfo.actionFlag === 'login'){
        navigate('/login');
      }     
  };
    return (
    <>
     <AlertModal open={modalOpen} close={closeModal} action={doModal} header={modalInfo.headerMsg} 
            isConfirm={modalInfo.isConfirm} isMessage={modalInfo.isMessage} isCancel={modalInfo.isCancel}>
            {modalInfo.mainMsg}
        </AlertModal>
        <section className="location_box">
            <ul className="location_001">
                <li>홈</li>
                <li>마이페이지</li>
                <li>서비스 문의</li>
            </ul>
        </section>

        <main>
            <section className="contants_box contants_box_mypage">

                <LeftMenu pageName='qna'/> 

                <section className="rightcontant_box">
                    <h2 className="tit_01">서비스 문의</h2>

                    <article className="table_search_box">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <form>
                                            <select className="search_input_02">
                                                <option value="선택">선택</option>
                                                <option value="제목">제목</option>
                                                <option value="제목+내용">제목+내용</option>
                                                <option value="작성자">작성자</option>
                                            </select>
                                        </form>
                                    </td>
                                    <td>
                                        <form>
                                            <input className="search_input_04" type="text" name="" />
                                        </form>
                                    </td>
                                    <td>
                                        <button type="button" className="button_05 button_black" onClick={getData}>검색</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </article>
   
                    <table className="table_box_01 table_box_02">
                        <colgroup>
                            <col width="60px" />
                            <col width="180px" />
                            <col width="610px" />
                            <col width="150px" />
                            <col width="150px" />
                        </colgroup>

                        <thead>
                            <tr>
                                <th>No</th>
                                <th>질문유형</th>
                                <th>글 제목</th>
                                <th>작성자</th>
                                <th>등록 일자</th>
                            </tr>
                        </thead>

                        <tbody>
                        {data && data.length > 0 && 
                            data.map((item, index) => (
                              <>
                            <tr key={index}>
                                <td>10</td>
                                <td>기프트 구매선물</td>
                                <td>
                                    <div className="table_03">
                                      <div className={item.noticeYn === "Y" ? "table_icon button_yellow" : "table_icon button_black"}> {item.noticeYn === "Y" ? "공개" : "비공개"}</div>
                                    
                                        <div className="table_03_002">
                                          {/* <span style={{ textDecoration: 'underline', cursor: 'pointer' }} */}
                                            <span style={{ cursor: 'pointer' }} 
                                                onClick= {(e) => {
                                                    // setSelectedGiftId('카페봄봄_아메리카노_기프트세트 세트세트세트_1');
                                                    
                                                    goQna(item.qnaNo);}}>
                                            {item.title}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td> {item.userId}</td>
                                <td>{(() => {
                                       const isoDate = item.updDttm;
                                       const date = new Date(isoDate);
                                       const year = date.getFullYear();
                                       const month = String(date.getMonth() + 1).padStart(2, "0");
                                       const day = String(date.getDate()).padStart(2, "0");
                                       return `${year}-${month}-${day}`;
                                    })()}</td>
                            </tr>                                               
                            <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <div className="table_03 table_03_001">
                                        <div className="table_icon button_gray_line_bg">답변</div>
                                        <div className="table_03_002">{item.aContent}</div>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            </>
                          ))}                    
                        </tbody>
                    </table>
                    <ul className="page_box">
                        { <Pagination
                            totalPage={totalPage} onPageChange={pageChange} 
                        />                        }
                        
                    </ul>

                    <article className="button_box_03">
                        <NavLink to='/qna-manage'>
                            <button type="button" className="button_01 button_03 button_red">질문하기</button>
                        </NavLink>
                    </article>

                </section>

            </section>
        </main>
    </>
    );
}